import React, { useState, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import "./resetPassword.css";

const ResetPassword = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [password2, setPassword2] = useState("");
  const [passMatched, setPassMatched] = useState(false);
  const [tempPassResetCode, setTempPassResetCode] = useState("");
  const [message, setMessage] = useState("");
  const [messageTempCode, setMessageTempCode] = useState("");
  const navigate = useNavigate();
  const messageRef = useRef(null);
  const [showPassword1, setShowPassword1] = useState(null);
  const [showPassword2, setShowPassword2] = useState(null);

  useEffect(() => {
    if (messageTempCode && messageRef.current) {
      messageRef.current.scrollIntoView({ behavior: "smooth" });
    }
  }, [messageTempCode]);

  const handleSendVerificationCode = (e) => {
    e.preventDefault();
    const generateTempCode = async () => {
      const response = await fetch(
        "/api/auth/admin-access/reset-password/send-temp-code",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ email }),
        }
      );
      if (response.ok) {
        setMessageTempCode(
          "Please check your email for the temporary varification code."
        );
      } else {
        console.log("Code generation failed.");
      }
    };
    generateTempCode();
  };

  const handleMatch = (e) => {
    setPassword2(e.target.value.trim());
    if (password.length > 0) {
      if (e.target.value.trim() !== password) {
        setPassMatched(false);
      } else {
        setPassMatched(true);
      }
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const response = await fetch(
      "/api/auth/admin-access/reset-password/create-new-password",
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ email, password, tempPassResetCode }),
      }
    );

    const data = await response.json();
    if (response.ok) {
      navigate("/login");
    } else {
      setMessage("Something has gone wrong... Please try again.");
    }
  };

  const handleShowPassword = (num) => {
    if (num === 1) {
      setShowPassword1((prev) => !prev);
    } else if (num === 2) {
      setShowPassword2((prev) => !prev);
    }
  };

  return (
    <div className="reset-page">
      <div className="reset-container">
        <h2>Reset password</h2>
        <form>
          <label htmlFor="email">
            Enter the email associated with your account:
          </label>
          <input
            type="text"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            required
          />

          <button type="submit" onClick={(e) => handleSendVerificationCode(e)}>
            Send Verification Code
          </button>

          <div ref={messageRef} style={{ marginBottom: "1rem;" }}></div>
          {messageTempCode && <div className="message">{messageTempCode}</div>}
        </form>

        {messageTempCode && (
          <>
            <h2>Create New Password</h2>
            <form onSubmit={handleSubmit}>
              <label htmlFor="password">New Password:</label>
              <div className="password-wrapper">
                <input
                  type={showPassword1 ? "text" : "password"}
                  value={password}
                  onChange={(e) => setPassword(e.target.value.trim())}
                  required
                />
                <img
                  onClick={() => handleShowPassword(1)}
                  src="https://img.icons8.com/ios-glyphs/30/visible--v1.png"
                  alt="visible--v1"
                />
              </div>
              <label htmlFor="reenter-password">Re-Enter New Password:</label>
              <div className="password-wrapper">
                <img
                  onClick={() => handleShowPassword(2)}
                  src="https://img.icons8.com/ios-glyphs/30/visible--v1.png"
                  alt="visible--v1"
                />
                <input
                  type={showPassword2 ? "text" : "password"}
                  value={password2}
                  onChange={(e) => {
                    handleMatch(e);
                  }}
                  required
                />
              </div>

              {password && !passMatched && (
                <div className="passwords-not-matched">
                  Passwords do not match.
                </div>
              )}
              <label htmlFor="temporary-code">
                Enter Temporary Verification Code:
              </label>
              <input
                type="temp-code"
                value={tempPassResetCode}
                onChange={(e) => setTempPassResetCode(e.target.value.trim())}
                required
              />
              <button type="submit">Reset</button>
            </form>
          </>
        )}
      </div>
    </div>
  );
};

export default ResetPassword;
