import "./page404.css";

const Page404 = () => {
  return (
    <div className="page-404">
      <div className="container-404">
        <h2>Oops! We couldn't find this page</h2>
        <h3>
          The page you’re looking for doesn’t exist. Don’t worry, we’ll help you
          get back on the right path!
        </h3>
        <h3>
          <a href="/" style={{ color: "var(--site-blue)" }}>
            Take me home
            <img
              src="https://img.icons8.com/00c8dc/ios/100/circled-right-2.png"
              alt="circled-right-2"
            />
          </a>
        </h3>
      </div>
    </div>
  );
};

export default Page404;
