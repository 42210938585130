import Bold from "./style/styleBold";

const PagePrivacyPolicy = () => {
  return (
    <div className="privacy-policy-page">
      <div className="privacy-policy-container">
        <h3>
          Bent Christy Graduate Assistance Program (BCGAP) Nonprofit
          Organization Privacy Policy
        </h3>
        <p>Effective Date: October 1, 2024</p>
        <h4>Introduction</h4>
        <p>
          Bent Christy Graduate Assistance Program (BCGAP) is committed to
          protecting the privacy of our members, donors, volunteers, and other
          stakeholders. This Privacy Policy outlines our practices regarding the
          collection, use, and disclosure of personal information that we
          collect from you or that you provide to us through our website,
          events, and other interactions.
        </p>
        <h4>Information We Collect</h4>
        <p>
          We may collect the following types of personal information:
          <ol>
            <li>
              <Bold> Contact Information:</Bold> Such as your name, email
              address, phone number, and mailing address.
            </li>
            <li>
              <Bold>Demographic Information:</Bold> Such as age, gender, and
              interests.
            </li>
            <li>
              <Bold>Payment Information:</Bold> Such as credit card details and
              billing address when you make a donation or purchase.
            </li>
            <li>
              <Bold> Event Participation Information:</Bold> Information you
              provide when registering for events, including dietary preferences
              and special requirements.
            </li>
            <li>
              <Bold>Website Usage Information:</Bold> Information collected
              automatically through cookies and other tracking technologies
              about how you interact with our website.
            </li>
          </ol>
        </p>
        <h3>How We Use Your Information</h3>
        <p>
          We may use your personal information for the following purposes:
          <ol>
            <li>
              <Bold>Communication:</Bold>To send you with updates on college
              loan repayment and scholarship decisions, as well as newsletters
              and information regarding our activities and events.
            </li>
            <li>
              <Bold>Donor Management:</Bold>To process donations and send
              acknowledgments.
            </li>
            <li>
              <Bold>Event Management: </Bold>To manage registrations and
              communicate important information related to events.
            </li>{" "}
            <li>
              <Bold>Research and Analysis:</Bold> To better understand our
              audience and improve our services.
            </li>
            <li>
              <Bold>Legal Compliance: </Bold>To comply with applicable laws and
              regulations.
            </li>
          </ol>
        </p>

        <h3> Sharing Your Information</h3>
        <p>
          We do not sell or rent your personal information to third parties. We
          may share your information in the following circumstances:
          <ol>
            <li>
              <Bold>Service Providers: </Bold>We may share your information with
              third-party service providers who assist us in operating our
              organization, such as payment processors, email service providers,
              and event management platforms.
            </li>
            <li>
              <Bold>Legal Requirements:</Bold> We may disclose your information
              if required to do so by law or in response to legal requests.
            </li>
          </ol>
        </p>

        <h3> Security of Your Information</h3>
        <p>
          We take reasonable measures to protect the personal information we
          collect from loss, theft, misuse, and unauthorized access. However, no
          method of transmission over the internet or electronic storage is 100%
          secure. While we strive to protect your personal information, we
          cannot guarantee its absolute security.
        </p>

        <h3> Your Rights</h3>
        <p>
          You have the following rights regarding your personal information:
          <ol>
            <li>
              <Bold>Access: </Bold>You have the right to request access to the
              personal information we hold about you.
            </li>
            <li>
              <Bold>Correction:</Bold> You have the right to request that we
              correct any inaccuracies in your personal information.
            </li>
            <li>
              <Bold>Deletion: </Bold>You have the right to request that we
              delete your personal information, subject to certain exceptions.
            </li>
            <li>
              <Bold>Opt-Out: </Bold>You have the right to opt out of receiving
              marketing communications from us at any time.
            </li>
          </ol>
        </p>
        <h3>Cookies and Tracking Technologies</h3>
        <p>
          Our website may use cookies and similar technologies to enhance user
          experience. You can choose to accept or decline cookies. Most web
          browsers automatically accept cookies, but you can usually modify your
          browser setting to decline cookies if you prefer. Note that declining
          cookies may prevent you from taking full advantage of our website.
        </p>

        <h3> Changes to This Privacy Policy</h3>
        <p>
          We may update this Privacy Policy from time to time. We will notify
          you of any changes by posting the new Privacy Policy on our website.
          Your continued use of our services after any changes constitute your
          acceptance of the new Privacy Policy.
        </p>

        <h3> Contact Us</h3>
        <p>
          If you have any questions about this Privacy Policy or our practices
          regarding your personal information, please contact us at:
        </p>

        <h3>
          Bent Christy Graduate Assistance Program (BCGAP) Nonprofit
          Organization
        </h3>
        <p>
          4 Sunglow Drive <br />
          Ewing, NJ 08638 <br />
          Email: bcgap@outlook.com <br />
          Phone: 609-912-1023
        </p>
      </div>
    </div>
  );
};

export default PagePrivacyPolicy;
