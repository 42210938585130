import "./PageForParents-2.css";
import Bold from "../style/styleBold";
import Red from "../style/styleRed";
import Label from "../style/styleLabel";
import { useState, useRef, useEffect } from "react";
import PopUpPrivacyPolicy from "../popUpPrivacyPolicy";

const PageForParents = () => {
  const [dropdownMenu, setDropDownMenu] = useState({
    0: false,
    1: false,
    3: false,
    4: false,
    5: false,
  });
  const [submissionSuccess, setSubmissionSuccess] = useState(false);
  const [showPopPrivacy, setShowPopPrivacy] = useState(false);
  const [parentSocialMismatch, setParentSocialMismatch] = useState(false);
  const [studentSocialMismatch, setStudentSocialMismatch] = useState(false);
  const [confirmParentSocial, setConfirmParentSocial] = useState();
  const [confirmStudentSocial, setConfirmStudentSocial] = useState();
  const applicationAboutRef = useRef(null);
  const applicationDeadlinesRef = useRef(null);
  const applicationFormRef = useRef(null);
  const loanAgreementRef = useRef(null);
  const loanStatementRef = useRef(null);
  const applicationLetterRef = useRef(null);
  const currentIncomeRef = useRef(null);
  const loanHistoryRef = useRef(null);
  const creditReportRef = useRef(null);
  const transcriptRef = useRef(null);
  const checkboxRef = useRef(null);
  const [showContent, setShowContent] = useState([false, false, false]);
  const [application, setApplication] = useState({
    parentFirstName: "",
    parentMidInitial: "",
    parentLastName: "",
    parentSuffix: "",
    parentPhone: "",
    parentEmail: "",
    parentAddress: "",
    parentAddress2: "",
    parentCity: "",
    parentState: "",
    parentZip: "",
    parentDateOfBirth: "",
    parentCitizenship: "",
    parentSocialSecurity: "",
    studentFirstName: "",
    studentMidInitial: "",
    studentLastName: "",
    studentDateOfBirth: "",
    studentCitizenship: "",
    studentSocialSecurity: "",
    studentConfirmSocial: "",
    studentAddress: "",
    collegeAttended: "",
    loanYear: "",
    collegeMajor: "",
    yearEnrolled: "",
    yearGraduated: "",
    transcript: null,
    loanAmount: "",
    loanAgreement: null,
    loanStatement: null,
    applicationLetter: null,
    currentIncome: null,
    employmentStatus: "",
    grossIncome: "",
    loanHistory: null,
    creditReport: null,
    householdSize: "",
    houseOwnedOrRent: "",
  });

  const handleClickOutside = (event) => {
    if (!event.target.closest(".dropdown-menu")) {
      setDropDownMenu((prevState) => {
        return Object.keys(prevState).reduce((acc, key) => {
          acc[key] = false;
          return acc;
        }, {});
      });
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const yearsList = Array.from({ length: 2035 - 2015 + 1 }, (v, i) => i + 2015);

  const toggleDropdown = (dropdownId) => {
    setDropDownMenu((prev) => ({
      ...prev,
      [dropdownId]: !prev[dropdownId],
    }));
  };

  const handleShowContent = (index) => {
    setShowContent((prevShowContent) =>
      prevShowContent.map((item, i) => (i === index ? !item : item))
    );
  };

  const handleChange = (e) => {
    const { name, type, value, files } = e.target;

    if (name === "parentSocialSecurity") {
      let ssnValue = e.target.value.replace(/\D/g, "");
      if (ssnValue.length > 9) {
        ssnValue = ssnValue.slice(0, 9);
      }

      if (ssnValue.length > 5) {
        ssnValue = ssnValue.replace(/(\d{3})(\d{2})(\d{0,4})/, "$1-$2-$3");
      } else if (ssnValue.length > 3) {
        ssnValue = ssnValue.replace(/(\d{3})(\d{0,2})/, "$1-$2");
      }

      if (ssnValue !== confirmParentSocial) {
        setParentSocialMismatch(true);
      } else {
        setParentSocialMismatch(false);
      }

      setApplication((prevState) => ({
        ...prevState,
        [name]: ssnValue,
      }));
    } else if (name === "studentSocialSecurity") {
      let ssnValue = e.target.value.replace(/\D/g, "");
      if (ssnValue.length > 9) {
        ssnValue = ssnValue.slice(0, 9);
      }
      if (ssnValue.length > 5) {
        ssnValue = ssnValue.replace(/(\d{3})(\d{2})(\d{0,4})/, "$1-$2-$3");
      } else if (ssnValue.length > 3) {
        ssnValue = ssnValue.replace(/(\d{3})(\d{0,2})/, "$1-$2");
      }

      if (ssnValue !== confirmStudentSocial) {
        setStudentSocialMismatch(true);
      } else {
        setStudentSocialMismatch(false);
      }

      setApplication((prevState) => ({
        ...prevState,
        [name]: ssnValue,
      }));
    } else if (name === "parentPhone") {
      let phoneValue = e.target.value.replace(/\D/g, "");
      if (phoneValue.length > 10) {
        phoneValue = phoneValue.slice(0, 10);
      }

      let formattedPhone = phoneValue;

      if (phoneValue.length > 6) {
        formattedPhone = phoneValue.replace(
          /(\d{3})(\d{3})(\d{0,4})/,
          "($1) $2-$3"
        );
      } else if (phoneValue.length > 3) {
        formattedPhone = phoneValue.replace(/(\d{3})(\d{0,3})/, "($1) $2");
      }

      setApplication((prevState) => ({
        ...prevState,
        [name]: formattedPhone,
      }));
    } else if (name === "confirmParentSocial") {
      let ssnValue = e.target.value.replace(/\D/g, "");
      if (ssnValue.length > 9) {
        ssnValue = ssnValue.slice(0, 9);
      }

      if (ssnValue.length > 5) {
        ssnValue = ssnValue.replace(/(\d{3})(\d{2})(\d{0,4})/, "$1-$2-$3");
      } else if (ssnValue.length > 3) {
        ssnValue = ssnValue.replace(/(\d{3})(\d{0,2})/, "$1-$2");
      }

      if (ssnValue !== application.parentSocialSecurity) {
        setParentSocialMismatch(true);
      } else {
        setParentSocialMismatch(false);
      }

      setConfirmParentSocial(ssnValue);
    } else if (name === "confirmStudentSocial") {
      let ssnValue = e.target.value.replace(/\D/g, "");
      if (ssnValue.length > 9) {
        ssnValue = ssnValue.slice(0, 9);
      }

      if (ssnValue.length > 5) {
        ssnValue = ssnValue.replace(/(\d{3})(\d{2})(\d{0,4})/, "$1-$2-$3");
      } else if (ssnValue.length > 3) {
        ssnValue = ssnValue.replace(/(\d{3})(\d{0,2})/, "$1-$2");
      }

      if (ssnValue !== application.studentSocialSecurity) {
        setStudentSocialMismatch(true);
      } else {
        setStudentSocialMismatch(false);
      }

      setConfirmStudentSocial(ssnValue);
    } else if (type === "file") {
      setApplication((prevState) => ({
        ...prevState,
        [name]: files[0],
      }));
    } else if (name === "loanAmount") {
      setApplication((prevState) => ({
        ...prevState,
        [name]: formatCurrency(value),
      }));
    } else if (name === "grossIncome") {
      setApplication((prevState) => ({
        ...prevState,
        [name]: formatCurrency(value),
      }));
    } else {
      setApplication((prevState) => ({
        ...prevState,
        [name]: value,
      }));
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    if (
      confirmStudentSocial !== application.studentSocialSecurity ||
      confirmParentSocial !== application.parentSocialSecurity
    ) {
      return;
    }

    const formData = new FormData();

    Object.keys(application).forEach((key) => {
      if (application[key] !== null) {
        formData.append(key, application[key]);
      }
    });

    fetch("/api/parents/submit-new-application", {
      method: "POST",
      body: formData,
    })
      .then((response) => response.json())
      .then((data) => {
        setSubmissionSuccess(true);
        setConfirmParentSocial("");
        setConfirmStudentSocial("");
        setApplication({
          parentFirstName: "",
          parentMidInitial: "",
          parentLastName: "",
          parentSuffix: "",
          parentPhone: "",
          parentEmail: "",
          parentAddress: "",
          parentAddress2: "",
          parentCity: "",
          parentState: "",
          parentZip: "",
          parentDateOfBirth: "",
          parentCitizenship: "",
          parentSocialSecurity: "",
          studentFirstName: "",
          studentMidInitial: "",
          studentLastName: "",
          studentDateOfBirth: "",
          studentCitizenship: "",
          studentSocialSecurity: "",
          studentConfirmSocial: "",
          studentAddress: "",
          collegeAttended: "",
          loanYear: "",
          collegeMajor: "",
          yearEnrolled: "",
          yearGraduated: "",
          transcript: null,
          loanAmount: "",
          loanAgreement: null,
          loanStatement: null,
          applicationLetter: null,
          currentIncome: null,
          employmentStatus: "",
          grossIncome: "",
          loanHistory: null,
          creditReport: null,
          householdSize: "",
          houseOwnedOrRent: "",
        });

        if (loanAgreementRef.current) loanAgreementRef.current.value = "";
        if (loanStatementRef.current) loanStatementRef.current.value = "";
        if (applicationLetterRef.current)
          applicationLetterRef.current.value = "";
        if (currentIncomeRef.current) currentIncomeRef.current.value = "";
        if (loanHistoryRef.current) loanHistoryRef.current.value = "";
        if (creditReportRef.current) creditReportRef.current.value = "";
        if (transcriptRef.current) transcriptRef.current.value = "";

        if (checkboxRef.current) {
          checkboxRef.current.checked = false;
        }

        setTimeout(() => {
          fetch("/api/send/send-email-new-application", {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify({
              message: "New application received",
            }),
          })
            .then((response) => {
              if (response.ok) {
                return response.json();
              } else {
                return response.text().then((text) => {
                  throw new Error(text);
                });
              }
            })
            .then((data) => {
              console.log("Success:", data);
            })
            .catch((error) => {
              console.error("Error triggering email:", error);
              alert("An error occurred while triggering the email.");
            });
        }, 5000);
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };

  const customStyles = {
    control: (provided) => ({
      ...provided,
      backgroundColor: "white",
      borderRadius: "2rem",
      border: "1px solid var(--site-orange)",
      width: "100%",
      fontSize: "1rem",
    }),
    option: (provided, state) => ({
      ...provided,
      backgroundColor: state.isSelected
        ? "var(--site-orange)"
        : state.isFocused
        ? "var(--site-orange)"
        : "white",
      color: state.isFocused ? "white" : "var(--site-dark-gray)",
      padding: ".8rem 1rem",
      cursor: "pointer",
    }),
    menu: (provided) => ({
      ...provided,
      borderRadius: "2rem",
      overflow: "hidden",
      padding: 0,
    }),
    singleValue: (provided) => ({
      ...provided,
      color: "red",
      fontWeight: "bold",
    }),
  };

  const formatCurrency = (value) => {
    const cleanValue = value.replace(/[^\d.]/g, "");
    const [integerPart, decimalPart] = cleanValue.split(".");
    const formattedInteger = integerPart
      ? parseInt(integerPart, 10).toLocaleString("en-US")
      : "";
    const formattedDecimal = decimalPart ? decimalPart.slice(0, 2) : "";
    return formattedDecimal
      ? `${formattedInteger}.${formattedDecimal}`
      : formattedInteger;
  };

  return (
    <div className="parents-page funding-page">
      <div className="funding-heading">
        Bent Christy Graduate Assistance Program (BCGAP) Parent Repayment
      </div>
      <div className="funding-content">
        <div className="text-image">
          <div className="image">
            <img
              style={{ objectPosition: "0 38%" }}
              src="./images/parents/parents2.webp"
              alt="parents-photo"
            />
          </div>
          <div className="text">
            <div className="paragraph">
              As dedicated parents, the burden of college loans can be
              overwhelming, sometimes forcing you to refinance homes, use
              retirement savings, or delay retirement to repay your children's
              college loans.
            </div>
            <div className="paragraph">
              <Bold>Bent Christy Graduate Assistance Program (BCGAP)</Bold>{" "}
              understands the challenges and stress of managing these
              repayments. If you're struggling with college loan repayments, we
              are here to help ease the burden. Contact us with any questions
              you may have about our program and how we can assist with your
              children's loan repayment. Our organization understands that
              managing student loan repayment can be challenging. If you're
              struggling with your college loan repayments, we are here to help.
            </div>
            <div className="paragraph">
              Our <Bold>Parent Repayment Program</Bold> is designed to alleviate
              the financial burden faced by many undergraduates and parents that
              are struggling to repay college loans.
            </div>{" "}
          </div>
        </div>
        <div
          style={{ marginTop: "2rem" }}
          className="subheading"
          ref={applicationAboutRef}
          onClick={() => handleShowContent(0)}
        >
          BCGAP Repayment Requirements{" "}
          <img
            style={{
              transform: showContent[0] ? "rotate(180deg)" : "rotate(0deg)",
            }}
            src="https://img.icons8.com/ios-filled/ff8332/100/expand-arrow--v1.png"
            alt="show-about-scholarship"
          />
        </div>
        <div
          className="application-content"
          style={{ display: showContent[0] ? "block" : "none" }}
        >
          <div>
            If you are a parent struggling to keep up with your child&#39;s
            college loan repayments, you are not alone. Our organization is here
            to help alleviate this financial burden. You are encouraged to apply
            for loan repayment assistance if your child meets the following
            criteria:
          </div>
          <div>
            <ul>
              <li>Attended a 4-year college</li>
              <li>
                {" "}
                Completed your degree in 4 years <Red>*</Red>
              </li>
              <li>Experiencing hardship due to student debt repayments</li>
            </ul>
            <div style={{ marginTop: ".5rem" }}></div>
            <Red> * </Red> If personal hardships (such as caring for an ill
            parent, financial need, etc.) delayed your child's graduation, you
            are still eligible to apply. Please detail your child's hardship in
            your application materials.
          </div>
        </div>
        <div
          className="subheading"
          ref={applicationDeadlinesRef}
          onClick={() => handleShowContent(1)}
        >
          BCGAP Repayment Documents
          <img
            style={{
              transform: showContent[1] ? "rotate(180deg)" : "rotate(0deg)",
            }}
            src="https://img.icons8.com/ios-filled/ff8332/100/expand-arrow--v1.png"
            alt="show-application-deadline"
          />
        </div>
        <div
          className="application-content"
          style={{ display: showContent[1] ? "block" : "none" }}
        >
          To apply for the BCGAP Parent Repayment, please be prepared to submit
          the following documents:
          <ol>
            <li>The Child's Official College Transcript</li>
            <li>Loan Agreement or Promissory Note</li>
            <li>Loan Servicer Statements</li>
            <li>Provide current income and expense </li>
            <li>
              Letter from your employer with Employment status: Full-time.
              Part-time, Laid-off
            </li>
            <li>Current Income Tax and employee pay stub </li>
            <li>Provide governance assistance if laid-off </li>
            <li>Loan repayment history record </li>
            <li>
              Credit Report showing the status of the student loans, and how
              loan repayment is affecting credit score{" "}
            </li>
            <li>
              Letter from your child's college showing his/her start and end
              date{" "}
            </li>
            <li>Application Letter (min. 500 words)</li>
            <ul>
              <div style={{ marginLeft: "-2rem", marginTop: ".5rem" }}>
                <b>In your letter, please discuss:</b>{" "}
              </div>
              <li>
                How does the loan repayment impact your current budget and
                overall financial stability?
              </li>
              <li>
                What sacrifices did you and/or your family have to make to cover
                the cost of your child college degree?
              </li>{" "}
              <li>
                How are you balancing loan repayment with other financial
                obligations, such as saving for retirement or covering daily
                expenses?
              </li>
              <li>
                How is the stress of repaying these loans affecting your
                emotional well-being and family relationships?
              </li>
              <li>
                If selected, how would the{" "}
                <Bold>
                  {" "}
                  Bent Christy Graduate Assistance Program (BCGAP) Repayment
                  Program
                </Bold>{" "}
                assist you in achieving your personal and/or professional goals?
              </li>
            </ul>
          </ol>
        </div>
        <div
          className="subheading"
          ref={applicationFormRef}
          onClick={() => handleShowContent(2)}
        >
          BCGAP Repayment Application
          <img
            style={{
              transform: showContent[2] ? "rotate(180deg)" : "rotate(0deg)",
            }}
            src="https://img.icons8.com/ios-filled/ff8332/100/expand-arrow--v1.png"
            alt="show-application-form"
          />
        </div>
        <div
          className="application-content"
          style={{ display: showContent[2] ? "block" : "none" }}
        >
          Please fill out the form below to submit an application for the{" "}
          <Bold>BCGAP Parent Repayment</Bold>:
          <form onSubmit={handleSubmit}>
            <Label>Parent Basic Info:</Label>
            <label htmlFor="parent-first-name">
              First Name <Red>*</Red>
            </label>
            <input
              type="text"
              id="parent-first-name"
              name="parentFirstName"
              value={application.parentFirstName}
              onChange={handleChange}
              required
            />
            <label htmlFor="parent-mid-initial">Middle Initial</label>
            <input
              type="text"
              id="parent-mid-initial"
              name="parentMidInitial"
              value={application.parentMidInitial}
              onChange={handleChange}
            />
            <label htmlFor="parent-last-name">
              Last Name <Red>*</Red>
            </label>
            <input
              type="text"
              id="parent-last-name"
              name="parentLastName"
              value={application.parentLastName}
              onChange={handleChange}
              required
            />
            <label htmlFor="parent-suffix">
              Suffix <Red>*</Red>
            </label>
            <input
              type="text"
              id="parent-suffix"
              name="parentSuffix"
              value={application.parentSuffix}
              onChange={handleChange}
              required
            />

            <Label>Parent Contact Info:</Label>
            <label htmlFor="parent-phone">
              Phone <Red>*</Red>
            </label>
            <input
              type="text"
              id="parent-phone"
              name="parentPhone"
              value={application.parentPhone}
              onChange={handleChange}
              required
            />
            <label htmlFor="parent-email">
              Email <Red>*</Red>
            </label>
            <input
              type="email"
              id="parent-email"
              name="parentEmail"
              value={application.parentEmail}
              onChange={handleChange}
              required
            />
            <label htmlFor="parent-address">
              Permanent Address <Red>*</Red>
            </label>
            <input
              type="text"
              id="parent-address"
              name="parentAddress"
              value={application.parentAddress}
              onChange={handleChange}
              required
            />
            <label htmlFor="parent-address-2">Address 2 </label>
            <input
              type="text"
              id="parent-address-2"
              name="parentAddress2"
              value={application.parentAddress2}
              onChange={handleChange}
            />
            <label htmlFor="parent-city">
              City <Red>*</Red>{" "}
            </label>
            <input
              type="text"
              id="parent-city"
              name="parentCity"
              value={application.parentCity}
              onChange={handleChange}
              required
            />
            <label htmlFor="parent-state">
              State <Red>*</Red>
            </label>
            <input
              type="text"
              id="parent-state"
              name="parentState"
              value={application.parentState}
              onChange={handleChange}
              required
            />
            <label htmlFor="parent-zip">
              Zip Code <Red>*</Red>
            </label>
            <input
              type="text"
              id="parent-zip"
              name="parentZip"
              value={application.parentZip}
              onChange={handleChange}
              required
            />

            <Label>Parent Personal Info:</Label>
            <label htmlFor="parent-dob">
              Date of Birth (MM/DD/YYYY) <Red>*</Red>
            </label>
            <input
              type="date"
              id="parent-dob"
              name="parentDateOfBirth"
              value={application.parentDateOfBirth}
              onChange={handleChange}
              required
            />
            <label htmlFor="parent-citizenship">
              Parent Citizenship <Red> * </Red>{" "}
            </label>

            <div className="dropdown-menu">
              <input
                type="text"
                id="parent-citizenship"
                name="parentCitizenship"
                onClick={() => {
                  toggleDropdown(0);
                }}
                value={application.parentCitizenship}
                onChange={handleChange}
                readOnly
                required
              />

              {dropdownMenu[0] && (
                <ul className="form-dropdown-list">
                  {["US citizen", "US national", "US permanent resident"].map(
                    (status) => (
                      <li
                        onClick={() => {
                          setApplication((prevState) => ({
                            ...prevState,
                            parentCitizenship: status,
                          }));
                          toggleDropdown(0);
                        }}
                      >
                        {status}
                      </li>
                    )
                  )}
                </ul>
              )}
            </div>

            <label htmlFor="parent-ssn">
              Social Security Number <Red> * </Red>
            </label>
            <input
              type="text"
              id="parent-ssn"
              name="parentSocialSecurity"
              value={application.parentSocialSecurity}
              onChange={handleChange}
              required
            />
            {parentSocialMismatch && (
              <div className="mismatch">
                The Social Security numbers you entered don't match.
              </div>
            )}
            <label htmlFor="parent-confirm-ssn">
              Confirm Social Security Number <Red> * </Red>
            </label>
            <input
              type="text"
              id="parent-confirm-ssn"
              name="confirmParentSocial"
              value={confirmParentSocial}
              onChange={handleChange}
              required
            />

            <Label>Student Info:</Label>
            <label htmlFor="student-first-name">
              First Name <Red>*</Red>
            </label>
            <input
              type="text"
              id="student-first-name"
              name="studentFirstName"
              value={application.studentFirstName}
              onChange={handleChange}
              required
            />
            <label htmlFor="student-mid-initial">Middle Initial</label>
            <input
              type="text"
              id="student-mid-initial"
              name="studentMidInitial"
              onChange={handleChange}
              value={application.studentMidInitial}
            />
            <label htmlFor="student-last-name">
              Last Name <Red>*</Red>
            </label>
            <input
              type="text"
              id="student-last-name"
              name="studentLastName"
              value={application.studentLastName}
              onChange={handleChange}
              required
            />
            <label htmlFor="student-dob">
              Date of Birth (MM/DD/YYYY) <Red>*</Red>
            </label>
            <input
              type="date"
              id="student-dob"
              name="studentDateOfBirth"
              value={application.studentDateOfBirth}
              onChange={handleChange}
              required
            />
            <label htmlFor="student-citizenship">
              Student Citizenship <Red> * </Red>{" "}
            </label>

            <div className="dropdown-menu">
              <input
                type="text"
                id="student-citizenship"
                name="studentCitizenship"
                onClick={() => {
                  toggleDropdown(1);
                }}
                value={application.studentCitizenship}
                onChange={handleChange}
                readOnly
                required
              />

              {dropdownMenu[1] && (
                <ul className="form-dropdown-list">
                  {["US citizen", "US national", "US permanent resident"].map(
                    (status) => (
                      <li
                        onClick={() => {
                          setApplication((prevState) => ({
                            ...prevState,
                            studentCitizenship: status,
                          }));
                          toggleDropdown(1);
                        }}
                      >
                        {status}
                      </li>
                    )
                  )}
                </ul>
              )}
            </div>

            <label htmlFor="studentSsn">
              Social Security Number <Red> * </Red>
            </label>
            <input
              type="text"
              id="student-ssn"
              name="studentSocialSecurity"
              value={application.studentSocialSecurity}
              onChange={handleChange}
              required
            />
            {studentSocialMismatch && (
              <div className="mismatch">
                The Social Security numbers you entered don't match.
              </div>
            )}
            <label htmlFor="student-confirm-ssn">
              Confirm Social Security Number <Red> * </Red>
            </label>
            <input
              type="text"
              id="student-confirm-ssn"
              name="confirmStudentSocial"
              value={confirmStudentSocial}
              onChange={handleChange}
              required
            />

            <label htmlFor="student-address">
              Address if not the same as parent{" "}
            </label>
            <input
              type="text"
              id="student-address"
              name="studentAddress"
              value={application.studentAddress}
              onChange={handleChange}
            />

            <label htmlFor="college-attended">
              College Attended <Red>*</Red>
            </label>
            <input
              type="text"
              id="college-attended"
              name="collegeAttended"
              value={application.collegeAttended}
              onChange={handleChange}
              required
            />
            <label htmlFor="loan-year">
              Year in School Loan Will Help Cover <Red>*</Red>
            </label>
            <input
              type="text"
              id="loan-year"
              name="loanYear"
              value={application.loanYear}
              onChange={handleChange}
              required
            />
            <label htmlFor="major-in-college">
              College Major (and Minor, if applicable) <Red>*</Red>
            </label>
            <input
              type="text"
              id="major-in-college"
              name="collegeMajor"
              value={application.collegeMajor}
              onChange={handleChange}
              required
            />
            <label htmlFor="year-enrolled">
              Year Enrolled <Red>*</Red>
            </label>

            <div className="dropdown-menu">
              <input
                type="text"
                id="year-enrolled"
                name="yearEnrolled"
                value={application.yearEnrolled}
                onClick={() => {
                  toggleDropdown(3);
                  if (dropdownMenu[4] === true) {
                    toggleDropdown(4);
                  }
                }}
                onChange={handleChange}
                readOnly
                required
              />

              {dropdownMenu[3] && (
                <ul className="form-dropdown-list years">
                  {yearsList.map((year) => (
                    <li
                      onClick={() => {
                        setApplication((prevState) => ({
                          ...prevState,
                          yearEnrolled: year,
                        }));
                        toggleDropdown(3);
                      }}
                    >
                      {year}
                    </li>
                  ))}
                </ul>
              )}
            </div>

            <label htmlFor="year-completed">
              Year Completed <Red>*</Red>
            </label>

            <div className="dropdown-menu">
              <input
                type="text"
                id="year-completed"
                name="yearGraduated"
                value={application.yearGraduated}
                onClick={() => {
                  toggleDropdown(4);
                  if (dropdownMenu[3] === true) {
                    toggleDropdown(3);
                  }
                }}
                onChange={handleChange}
                readOnly
                required
              />

              {dropdownMenu[4] && (
                <ul className="form-dropdown-list years">
                  {yearsList.map((year) => (
                    <li
                      onClick={() => {
                        setApplication((prevState) => ({
                          ...prevState,
                          yearGraduated: year,
                        }));
                        toggleDropdown(4);
                      }}
                    >
                      {year}
                    </li>
                  ))}
                </ul>
              )}
            </div>

            <label htmlFor="college-transcript">
              Transcript <Red>*</Red>
            </label>
            <input
              ref={transcriptRef}
              type="file"
              id="college-transcript"
              name="transcript"
              onChange={handleChange}
              required
            />
            <Label>Parent Financial Obligation:</Label>
            <label htmlFor="loan-amount">
              College Loan Amount <Red>*</Red>
            </label>
            <input
              type="text"
              id="loan-amount"
              name="loanAmount"
              value={application.loanAmount}
              onChange={handleChange}
              required
            />
            <label htmlFor="loan-agreement">
              Loan Agreement or Promissory Note <Red>*</Red>
            </label>
            <input
              ref={loanAgreementRef}
              type="file"
              id="loan-agreement"
              name="loanAgreement"
              onChange={handleChange}
              required
            />

            <label htmlFor="loan-statement">
              Loan Servicer Statements <Red>*</Red>
            </label>
            <input
              ref={loanStatementRef}
              type="file"
              id="loan-statement"
              name="loanStatement"
              onChange={handleChange}
              required
            />

            <label htmlFor="application-letter">
              Application Letter (See the instructions above) <Red>*</Red>
            </label>
            <input
              ref={applicationLetterRef}
              type="file"
              id="application-letter"
              name="applicationLetter"
              onChange={handleChange}
              required
            />

            <Label>Proof of Financial Need Info: </Label>
            <label htmlFor="current-expense">
              Current income and expense documentation <Red>*</Red>
            </label>
            <input
              ref={currentIncomeRef}
              type="file"
              id="current-income"
              name="currentIncome"
              onChange={handleChange}
              required
            />
            <label htmlFor="employment-status">
              Employment Status <Red>*</Red>
            </label>

            <div className="dropdown-menu">
              <input
                type="text"
                id="employment-status"
                name="employmentStatus"
                value={application.employmentStatus}
                onClick={() => {
                  toggleDropdown(5);
                }}
                onChange={handleChange}
                readOnly
                required
              />

              {dropdownMenu[5] && (
                <ul className="form-dropdown-list">
                  {["Full-time", "Part-time", "Laid-off"].map((stat) => (
                    <li
                      onClick={() => {
                        setApplication((prevState) => ({
                          ...prevState,
                          employmentStatus: stat,
                        }));
                        toggleDropdown(5);
                      }}
                    >
                      {stat}
                    </li>
                  ))}
                </ul>
              )}
            </div>

            <label htmlFor="gross-income">
              Gross Annual Income <Red>*</Red>
            </label>
            <input
              type="text"
              id="gross-income"
              name="grossIncome"
              value={application.grossIncome}
              onChange={handleChange}
              required
            />

            <label htmlFor="loan-repayment-history">
              Loan repayment history record <Red>*</Red>
            </label>
            <input
              ref={loanHistoryRef}
              type="file"
              id="loan-repayment-history"
              name="loanHistory"
              onChange={handleChange}
              required
            />

            <label htmlFor="credit-report">
              Credit Report showing the status of the student loans, and how
              loan repayment is affecting credit score <Red>*</Red>
            </label>
            <input
              ref={creditReportRef}
              type="file"
              id="credit-report"
              name="creditReport"
              onChange={handleChange}
              required
            />

            <label htmlFor="household-size">
              Household size <Red>*</Red>
            </label>
            <input
              type="text"
              id="household-size"
              name="householdSize"
              value={application.householdSize}
              onChange={handleChange}
              required
            />

            <label htmlFor="house-status">
              House Own / Rent <Red>*</Red>
            </label>

            <div className="dropdown-menu">
              <input
                type="text"
                name="houseOwnedOrRent"
                onClick={() => {
                  toggleDropdown(2);
                }}
                value={application.houseOwnedOrRent}
                onChange={handleChange}
                readOnly
                required
              />

              {dropdownMenu[2] && (
                <ul className="form-dropdown-list">
                  {["Owned", "Rent"].map((house) => (
                    <li
                      onClick={() => {
                        setApplication((prevState) => ({
                          ...prevState,
                          houseOwnedOrRent: house,
                        }));
                        toggleDropdown(2);
                      }}
                    >
                      {house}
                    </li>
                  ))}
                </ul>
              )}
            </div>

            <label htmlFor="policy" className="policy">
              <input type="checkbox" name="policy" />
              <span>
                By checking this box you are consent to being entered for a
                chance to win our Bent Christy Graduate Assistance Program
                (BCGAP) scholarship, processing of your Personal Information in
                Accordance with our{" "}
                <u
                  className="privacy-link"
                  onClick={() => setShowPopPrivacy(true)}
                >
                  Privacy Policy
                </u>
                .
              </span>
            </label>
            <input type="submit" value="Submit" />
          </form>
        </div>
      </div>
      {showPopPrivacy && (
        <div className="pop-privacy-policy">
          <PopUpPrivacyPolicy setShowPopPrivacy={setShowPopPrivacy} />
        </div>
      )}
    </div>
  );
};

export default PageForParents;
