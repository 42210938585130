const individualTeammembersDB = [
  {
    id: 0,
    photo: "/images/home/headshot1.jpg",
    name: "Grace Bent",
    role: "Founder and Chief Executive Officer",
    email: "",
    blurb:
      "Grace Bent is the Founder and Chief Executive Officer. She brings together her deep passion for education and a strong commitment to tackling the student debt crisis. She is driven by the belief that financial barriers should never limit access to higher education, and she leads BCGAP with a clear vision for equity in higher education.  \n\n  Grace is responsible for shaping the organization’s strategic direction and overall performance, ensuring that its mission remains the guiding force behind every decision. Under her leadership, BCGAP serves as a vital resource for graduates and families navigating the complexities of student loan repayment. \n\n Grace holds a Bachelor’s degree in Business Administration and Marketing from Rutgers University, an MBA in Global Management from the University of Phoenix, and is currently pursuing a Doctorate at Liberty University.",
  },

  {
    id: 1,
    photo: "/images/home/headshot1.jpg",
    name: "Christina Emile",
    role: "Executive Director and Chief Operating Officer",
    email: "",
    blurb:
      "Christina Emile serves as the Executive Director and Chief Operating Officer. She oversees the internal operations, ensuring the efficient delivery of programs and services while maintaining mission focus and financial sustainability. Working closely with the CEO, she helps define long-term goals and ensures that operations support these goals. \n\n Christina fosters partnerships with community organizations, funders, and government agencies to advance BCGAP’s mission, while providing operational insights to the Board of Directors for critical decision-making. \n\n Outside her role at BCGAP, Christina serves on the Alumnae Board of Directors for Stuart Country Day School in Princeton, New Jersey. She holds a Bachelor of Science in Kinesiology from Temple University and a Master’s in Public Health from Liberty University.",
  },

  {
    id: 2,
    photo: "/images/home/headshot1.jpg",
    name: "Lita Graham",
    role: "Director of Chief Creative Officer",
    email: "",
    blurb:
      "Lita Graham serves as the Director of Chief Creative Officer.  She spearheads the organization’s creative strategy to elevate its mission and messaging. \n\n Lita leads cross-functional collaboration, market analysis, and the development of innovative merchandise strategies, ensuring seamless consumer experiences that align with BCGAP’s overall business objectives. Lita is responsible for defining, maintaining, and evolving the organization’s brand identity, ensuring consistency in tone, messaging, and visual representation across all platforms. \n\n With over 30 years of experience in strategic brand development and product management, Lita specializes in crafting narratives that deeply resonate with target audiences, driving brand visibility and sales growth. She holds an Honors Bachelor’s Degree in Fashion Design from the University of East London.",
  },
];

export default individualTeammembersDB;
