import { useState, useEffect } from "react";
import "./welcomeAdmin.css";
import { useNavigate, Link } from "react-router-dom";
import { useUser } from "../userContext";

const WelcomeAdmin = () => {
  const navigate = useNavigate();
  const [showMenu, setShowMenu] = useState(false);
  const { currentUser, setCurrentUser } = useUser();

  const handleShowAdminMenu = () => {
    setShowMenu(!showMenu);
  };

  const handleLogout = async () => {
    const response = await fetch("/api/auth/admin-access/logout", {
      method: "POST",
      credentials: "include",
    });

    if (response.ok) {
      const data = await response.json();
      setCurrentUser(null);
      navigate("/login");
    } else {
      const data = await response.json();
    }
  };
  if (!currentUser) {
    return <p>Please log in to access this page.</p>;
  }

  return (
    <div className="admin-welcome">
      <div className="welcome-group">
        <h3>
          Welcome {currentUser.firstName} {currentUser.lastName}!
        </h3>
        <div className="links">
          <Link className="link" to="/admin-access">
            Applications
          </Link>
          <Link className="link" to="/admin-access-archive">
            Archive
          </Link>
          {currentUser.role === "admin" && (
            <div className="link">
              <Link to="/admin-access-privileges">Privileges</Link>
              <img
                src="https://img.icons8.com/ios/100/lock--v1.png"
                alt="lock--v1"
              />
            </div>
          )}
          <div className="link" onClick={handleLogout}>
            Logout
          </div>
        </div>
      </div>
      <div className="admin-id" onClick={handleShowAdminMenu}>
        <img
          src="https://img.icons8.com/pastel-glyph/64/person-male--v3.png"
          alt="admin menu"
        />
      </div>
    </div>
  );
};

export default WelcomeAdmin;
