import { useState, useEffect, useRef } from "react";
import Bold from "../style/styleBold";
import { useParams, Link } from "react-router-dom";
import "./PageAdminIndividual.css";
import WelcomeAdmin from "./welcomeAdmin";
import { useUser } from "../userContext";

const PageAdminIndividualHighSchool = () => {
  const { id } = useParams();
  const [currentApplicant, setCurrentApplicant] = useState();
  const { currentUser } = useUser();
  const downloadLinkRef = useRef(null);

  useEffect(() => {
    const fetchApplicant = async () => {
      try {
        const response = await fetch(`/api/undergrad/get-individual/${id}`, {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            credentials: "include",
          },
        });

        if (response.ok) {
          console.log("An applicant fetched.");
          const data = await response.json();
          setCurrentApplicant(data);
        } else {
          console.error("Applicant not fetched.");
        }
      } catch (error) {
        console.error("Error:", error);
      }
    };

    fetchApplicant();
  }, []);

  const baseUrl =
    process.env.NODE_ENV === "production"
      ? "https://bcgap-test.nchernysheva.com"
      : "http://localhost:4010";

  const downloadPDF = async (applicantId) => {
    try {
      // Fetch the PDF from the backend
      const response = await fetch(
        `/api/undergrad/download-pdf/${applicantId}`
      );

      if (response.ok) {
        const blob = await response.blob();
        const url = window.URL.createObjectURL(blob);

        // Update the href of the <a> element dynamically
        if (downloadLinkRef.current) {
          downloadLinkRef.current.href = url;
          downloadLinkRef.current.download = `Applicant_PDF.pdf`;

          // Programmatically trigger the click event to start the download
          downloadLinkRef.current.click();
        }
      } else {
        console.error("Failed to download PDF");
      }
    } catch (error) {
      console.error("Error downloading PDF:", error);
    }
  };

  return (
    <>
      <WelcomeAdmin currentUser={currentUser} />
      <div className="individual-applicant-page">
        <table>
          <tbody>
            {currentApplicant && (
              <>
                <tr>
                  <td className="name">
                    <Bold>Name: </Bold>
                  </td>
                  <td className="name">
                    {currentApplicant.suffix} {currentApplicant.firstName}{" "}
                    {currentApplicant.middleName} {currentApplicant.lastName}
                  </td>
                </tr>
                <tr>
                  <td>
                    <Bold>Date of Birth: </Bold>
                  </td>
                  <td>
                    {new Date(currentApplicant.dateOfBirth).toLocaleDateString(
                      "en-US",
                      {
                        year: "numeric",
                        month: "2-digit",
                        day: "2-digit",
                      }
                    )}
                  </td>
                </tr>

                <tr>
                  <td>
                    <Bold>Social Security Number: </Bold>
                  </td>
                  <td>{currentApplicant.socialSecurity}</td>
                </tr>
                <tr>
                  <td>
                    <Bold>Citizenship: </Bold>
                  </td>
                  <td>{currentApplicant.citizenship}</td>
                </tr>

                <tr>
                  <td>
                    <Bold>Email: </Bold>
                  </td>
                  <td>{currentApplicant.email}</td>
                </tr>
                <tr>
                  <td>
                    <Bold>Phone: </Bold>
                  </td>
                  <td>{currentApplicant.phone}</td>
                </tr>
                <tr>
                  <td>
                    <Bold>Permanent Address: </Bold>
                  </td>
                  <td>
                    {currentApplicant.address} {currentApplicant.address2},{" "}
                    {currentApplicant.city}, {currentApplicant.state}{" "}
                    {currentApplicant.zip}
                  </td>
                </tr>
                <tr>
                  <td>
                    <Bold>College Attended: </Bold>
                  </td>
                  <td>{currentApplicant.collegeAttended}</td>
                </tr>
                <tr>
                  <td>
                    <Bold>College Major (and Minor, if applicable): </Bold>
                  </td>
                  <td>{currentApplicant.collegeMajor}</td>
                </tr>
                <tr>
                  <td>
                    <Bold>Year Enrolled: </Bold>
                  </td>
                  <td>{currentApplicant.yearEnrolled}</td>
                </tr>
                <tr>
                  <td>
                    <Bold>Year Graduated: </Bold>
                  </td>
                  <td>{currentApplicant.yearGraduated}</td>
                </tr>
                <tr>
                  <td>
                    <Bold>College Loan Amount: </Bold>
                  </td>
                  <td>{currentApplicant.loanAmount}</td>
                </tr>
                <tr>
                  <td>
                    <Bold>Year in School Loan Will Help Cover: </Bold>
                  </td>
                  <td>{currentApplicant.loanYear}</td>
                </tr>

                <tr>
                  <td>
                    <Bold>Employment Status: </Bold>
                  </td>
                  <td>{currentApplicant.employmentStatus}</td>
                </tr>
                <tr>
                  <td>
                    <Bold>Gross Annual Income: </Bold>
                  </td>
                  <td>{currentApplicant.grossIncome}</td>
                </tr>
                <tr>
                  <td>
                    <Bold>Household Size: </Bold>
                  </td>
                  <td>{currentApplicant.householdSize}</td>
                </tr>
                <tr>
                  <td>
                    <Bold>House Own / Rent: </Bold>
                  </td>
                  <td>{currentApplicant.houseOwnedOrRent}</td>
                </tr>
                {currentApplicant.files &&
                  currentApplicant.files.map((file) => (
                    <tr>
                      <td>
                        <Bold>{file.fileAlias}: </Bold>
                      </td>
                      <td>
                        <Link
                          to={`${baseUrl}/api/undergrad/download-file/${currentApplicant.undergrad_applicant_id}/${file.fileID}`}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          Download
                        </Link>
                      </td>
                    </tr>
                  ))}
                {(currentUser.role === "admin" ||
                  currentUser.role === "employee") && (
                  <tr>
                    <td className="pdf-download" colSpan="2">
                      <button
                        onClick={() =>
                          downloadPDF(currentApplicant.undergrad_applicant_id)
                        }
                      >
                        DOWNLOAD
                      </button>
                      {downloadLinkRef && (
                        <a ref={downloadLinkRef} style={{ display: "none" }}>
                          Open PDF
                        </a>
                      )}
                    </td>
                  </tr>
                )}
              </>
            )}
          </tbody>
        </table>
      </div>
    </>
  );
};

export default PageAdminIndividualHighSchool;
