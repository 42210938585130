import React, { useState, useEffect } from "react";
import { useNavigate, Link } from "react-router-dom";
import { useUser } from "../userContext";
import "./login.css";

const LoginPage = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const navigate = useNavigate();
  const [invalid, setInvalid] = useState(false);
  const { currentUser, setCurrentUser } = useUser();
  const [errorPrivilegesMessage, setErrorPrivilegesMessage] = useState();
  const [showPassword, setShowPassword] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();

    setInvalid(false);

    try {
      const response = await fetch("/api/auth/admin-access/login", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        credentials: "include",
        body: JSON.stringify({ email, password }),
      });

      if (response.ok) {
        const data = await response.json();
        setInvalid(false);
        setCurrentUser(data);
        window.location.replace("/admin-access");
      } else if (response.status === 403) {
        const data = await response.json();
        if (data.message === "Invalid privileges") {
          setErrorPrivilegesMessage(
            "You don't have the necessary privileges to access administrative pages."
          );
        }
      } else {
        setInvalid(true);
      }
    } catch (err) {
      console.error("Error occurred:", err);
      setInvalid(true);
    }
  };

  return (
    <div className="login-page">
      <div className="login-container">
        <h2>Login</h2>
        <form onSubmit={handleSubmit}>
          <div
            className={
              invalid || errorPrivilegesMessage
                ? "shake form-group"
                : "form-group"
            }
          >
            <label htmlFor="email">Email</label>
            <input
              type="text"
              id="email"
              name="email"
              value={email}
              onChange={(e) => {
                setEmail(e.target.value);
                setErrorPrivilegesMessage(null);
                setInvalid(false);
              }}
              required
            />
          </div>
          <div
            className={
              invalid || errorPrivilegesMessage
                ? "shake form-group"
                : "form-group"
            }
          >
            <label htmlFor="password">Password</label>
            <div className="password-wrapper">
              <input
                type={showPassword ? "text" : "password"}
                id="password"
                name="password"
                value={password}
                onChange={(e) => {
                  setPassword(e.target.value);
                  setErrorPrivilegesMessage(null);
                  setInvalid(false);
                }}
                required
              />
              <img
                onClick={() => setShowPassword(!showPassword)}
                src="https://img.icons8.com/ios-glyphs/30/visible--v1.png"
                alt="visible--v1"
              />
            </div>
          </div>

          {errorPrivilegesMessage && (
            <div className="invalid">{errorPrivilegesMessage}</div>
          )}
          {invalid && (
            <div className="invalid">Invalid username or password.</div>
          )}

          <button type="submit">Login</button>
        </form>
        <div className="reset-password">
          <Link to="/reset-password">Forgot your password?</Link>
        </div>
        <div className="register">
          <a href="/register-new-account">Don't have an account? Sign up!</a>
        </div>
      </div>
    </div>
  );
};

export default LoginPage;
