import { createContext, useState, useContext, useEffect } from "react";

const UserContext = createContext();

export const useUser = () => useContext(UserContext);

export const UserProvider = ({ children }) => {
  const [currentUser, setCurrentUser] = useState(null);
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [loading, setLoading] = useState(true); // To handle initial loading

  const baseUrl =
    process.env.NODE_ENV === "production"
      ? "https://bcgap-test.nchernysheva.com"
      : "http://localhost:4010";

  useEffect(() => {
    const checkAuthStatus = async () => {
      try {
        const response = await fetch(`${baseUrl}/api/auth/status`, {
          method: "GET",
          credentials: "include",
        });

        if (response.ok) {
          const data = await response.json();
          setCurrentUser(data.user); // Store the user data
          setIsAuthenticated(true); // Set auth to true
        } else {
          setCurrentUser(null); // Clear user data
          setIsAuthenticated(false); // Set auth to false
        }
      } catch (error) {
        console.error("Failed to check auth status", error);
        setCurrentUser(null); // Clear user data
        setIsAuthenticated(false); // Set auth to false
      } finally {
        setLoading(false); // Done loading
      }
    };

    checkAuthStatus();
  }, []);

  if (loading) {
    return; // Show a loading state while checking auth
  }

  return (
    <UserContext.Provider
      value={{ currentUser, setCurrentUser, isAuthenticated }}
    >
      {children}
    </UserContext.Provider>
  );
};
