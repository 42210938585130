import "./App.css";
import { useState, useEffect } from "react";
import PageHome from "./homePage/PageHome";
import PageAbout from "./aboutPages/PageAbout";
import PageDonate from "./getInvlovesPages/PageDonate";
import PageForUndergrads from "./applicationPages/PageForUndergrads-2";
import PageForParents from "./applicationPages/PageForParents-2";
import PageVolunteer from "./getInvlovesPages/PageVolunteer";
import PageForHighSchoolStudents from "./applicationPages/PageForHighSchoolStudents-2";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  useLocation,
} from "react-router-dom";
import HomeNavigation from "./navigation-footer/navigation";
import Footer from "./navigation-footer/footer";
import BcgapStore from "./bcgapStore/bcgapStore";
import BcgapCart from "./bcgapStore/bcgapCart";
import ContactUs from "./aboutPages/PageContact";
import PageTeam from "./aboutPages/PageTeam";
import PageBecomePartner from "./getInvlovesPages/PageBecomePartner";
import PageNewsStories from "./newsPages/PageNewsStories";
import PageAdmin from "./adminPages/PageAdminAccess";
import PageAdminIndividualHighSchool from "./adminPages/PageAdminIndividualHighSchool";
import LoginPage from "./authPages/login";
import ResetPassword from "./authPages/resetPassword";
import Privileges from "./adminPages/PagePrivileges";
import PageArchive from "./adminPages/PageArchive";
import Registration from "./authPages/register";
import { PrivateRoute } from "./privateRoute";
import { PrivateRouteAdmin } from "./privateRoute";
import { UserProvider, useUser } from "./userContext";
import Page404 from "./page404";
import PageAdminIndividualUndergrad from "./adminPages/PageAdminIndividualUndergrad";
import PageAdminIndividualParent from "./adminPages/PageAdminIndividualParents";
import IndividualTeamMember from "./aboutPages/individualTeamMember";
import PagePrivacyPolicy from "./privacyPolicy";

const App = () => {
  const [videoLoaded, setVideoLoaded] = useState(false);
  const location = useLocation();

  const isHomePage = location.pathname === "/";

  return (
    <>
      <UserProvider>
        {isHomePage && !videoLoaded && (
          <div className="loading-web">
            <img src="/icons/spinning.gif" alt="" />
          </div>
        )}

        <div
          className="App"
          style={{ opacity: isHomePage ? (videoLoaded ? "1" : "0") : "1" }}
        >
          <HomeNavigation />
          <Routes>
            <Route
              path="/"
              element={
                <PageHome
                  videoLoaded={videoLoaded}
                  setVideoLoaded={setVideoLoaded}
                />
              }
            />
            <Route
              path="/about-bent-christy-graduate-assistance-program"
              element={<PageAbout />}
            />
            <Route
              path="/donate-bent-christy-graduate-assistance-program"
              element={<PageDonate />}
            />
            <Route
              path="/bent-christy-graduate-assistance-program-for-college-students"
              element={<PageForUndergrads />}
            />
            <Route
              path="/bent-christy-graduate-assistance-program-for-parents"
              element={<PageForParents />}
            />
            <Route
              path="/bent-christy-graduate-assistance-program-for-high-school-students"
              element={<PageForHighSchoolStudents />}
            />
            <Route
              path="/bent-christy-graduate-assistance-program-volunteer-opportunities"
              element={<PageVolunteer />}
            />
            <Route
              path="/bent-christy-graduate-assistance-program-bcgap-store"
              element={<BcgapStore />}
            />
            <Route
              path="/bent-christy-graduate-assistance-program-bcgap-store-cart"
              element={<BcgapCart />}
            />
            <Route
              path="/bent-christy-graduate-assistance-program-contact-us"
              element={<ContactUs />}
            />
            <Route
              path="/bent-christy-graduate-assistance-program-our-team"
              element={<PageTeam />}
            />
            <Route
              path="/bent-christy-graduate-assistance-program-our-team-member/:id"
              element={<IndividualTeamMember />}
            />

            <Route
              path="/bent-christy-graduate-assistance-program-become-a-partner"
              element={<PageBecomePartner />}
            />
            <Route
              path="/bent-christy-graduate-assistance-program-news-stories"
              element={<PageNewsStories />}
            />

            <Route
              path="/bent-christy-graduate-assistance-program-news-stories"
              element={<PageNewsStories />}
            />

            <Route
              path="/bent-christy-graduate-assistance-program-privacy-policy"
              element={<PagePrivacyPolicy />}
            />

            <Route path="/login" element={<LoginPage />} />
            <Route path="/register-new-account" element={<Registration />} />
            <Route path="/reset-password" element={<ResetPassword />} />

            <Route path="*" element={<Page404 />} />

            <Route
              path="/admin-access"
              element={
                <PrivateRoute>
                  <PageAdmin />
                </PrivateRoute>
              }
            />

            <Route
              path="/admin-access-archive"
              element={
                <PrivateRoute>
                  <PageArchive />
                </PrivateRoute>
              }
            />

            <Route
              path="/admin-access-privileges"
              element={
                <PrivateRouteAdmin>
                  <Privileges />
                </PrivateRouteAdmin>
              }
            />

            <Route
              path="/show-applicant/high-school/:id/:name"
              element={
                <PrivateRoute>
                  <PageAdminIndividualHighSchool />
                </PrivateRoute>
              }
            />
            <Route
              path="/show-applicant/undergrad/:id/:name"
              element={
                <PrivateRoute>
                  <PageAdminIndividualUndergrad />
                </PrivateRoute>
              }
            />
            <Route
              path="/show-applicant/parent/:id/:name"
              element={
                <PrivateRoute>
                  <PageAdminIndividualParent />
                </PrivateRoute>
              }
            />
          </Routes>

          <Footer />
        </div>
      </UserProvider>
    </>
  );
};

export default App;
