import "./homeSection1-final.css";
import { useEffect } from "react";

const HomeSectionOne = () => {
  return (
    <>
      <div className="home-main-sections home-section-one">
        <div className="home-section-one-main-text"></div>
        <div className="three-ways-to-help">
          <div className="way-to-help">
            <div className="home-card-way-to-help-icon">
              <img
                src="https://img.icons8.com/external-tanah-basah-glyph-tanah-basah/ffbc2b/96/external-graduate-job-and-professions-avatars-tanah-basah-glyph-tanah-basah.png"
                alt="external-graduate-job-and-professions-avatars-tanah-basah-glyph-tanah-basah"
              />
            </div>
            <div className="card-way-to-help-content">
              <div className="card-heading-and-body">
                <div className="card-heading">College Graduate & Parent </div>
                <div className="card-body">
                  99% of undergraduate students and their parents owe over
                  $100,000 in college loan debt. The amount of this debt is
                  estimated to dramatically increase year after year.
                </div>
              </div>
            </div>
          </div>

          <div className="way-to-help">
            <div className="home-card-way-to-help-icon">
              <img
                src="https://img.icons8.com/ios-filled/ff3700/100/investment.png"
                alt="investment"
              />
            </div>
            <div className="card-way-to-help-content">
              <div className="card-heading-and-body">
                <div className="card-heading">College Loan Crisis </div>
                <div className="card-body">
                  The college loan crisis, now exceeding $1.7 trillion, poses a
                  significant financial challenge for millions. It is the
                  second-highest consumer debt and continues to grow annually.
                </div>
              </div>
            </div>
          </div>

          <div className="way-to-help">
            <div className="home-card-way-to-help-icon">
              <img
                src="https://img.icons8.com/ios-filled/00c8dc/100/helping-hand.png"
                alt="helping-hand"
              />
            </div>
            <div className="card-way-to-help-content">
              <div className="card-heading-and-body">
                <div className="card-heading">College Debt Free Future </div>
                <div className="card-body">
                  We aspire to create a world where financial obstacles do not
                  hinder educational opportunities, paving the way for a
                  brighter tomorrow filled with hope and possibility.
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default HomeSectionOne;
