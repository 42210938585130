import { useEffect, useState } from "react";
import "./PageTeam.css";
import Bold from "../style/styleBold";
import Typewriter from "../style/typewriterEffect";
import { useNavigate } from "react-router-dom";

const PageTeam = () => {
  const [photoVisible, setPhotoVisible] = useState(false);
  const navigate = useNavigate();

  return (
    <div className="team-page">
      <div className="team-header">
        <Typewriter text="being the change we need to see" />
      </div>
      <div className="team-intro">
        <span>
          Meet the dynamic team leading the{" "}
          <Bold>Bent Christy Graduate Assistance Program’s (BCGAP)</Bold>{" "}
          efforts to create a future where financial barriers or the weight of
          student debt no longer prevent individuals from reaching their full
          potential.
        </span>
      </div>
      <div className="team-container">
        <div className="photo-block">
          <a href="/bent-christy-graduate-assistance-program-our-team-member/0">
            <div className="team-photo">
              <img src="./images/home/headshot1.jpg" alt="" />
            </div>
            <div className="photo-caption">
              <div className="photo-caption-name">Grace Bent</div>
              <div className="photo-caption-role">
                Founder and Chief Executive Officer{" "}
              </div>
            </div>
          </a>
        </div>
        <div className="photo-block">
          <a href="/bent-christy-graduate-assistance-program-our-team-member/1">
            <div className="team-photo">
              <img src="./images/home/headshot1.jpg" alt="" />
            </div>
            <div className="photo-caption">
              <div className="photo-caption-name">Christina Emile</div>
              <div className="photo-caption-role">
                Executive Director and Chief Operating Officer{" "}
              </div>
            </div>
          </a>
        </div>
        <div className="photo-block">
          <a href="/bent-christy-graduate-assistance-program-our-team-member/2">
            <div className="team-photo">
              <img src="./images/home/headshot1.jpg" alt="" />
            </div>
            <div className="photo-caption">
              <div className="photo-caption-name">Lita Graham</div>
              <div className="photo-caption-role">
                Director of Chief Creative Officer{" "}
              </div>
            </div>
          </a>
        </div>
      </div>
    </div>
  );
};

export default PageTeam;
