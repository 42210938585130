import HomeHeroBanner from "./homeHeroBanner";
import HomeSectionOne from "./homeSection1-final";
import HomeSectionTwo from "./homeSection2";
import HomeSectionThree from "./homeSection3";
import Footer from "../navigation-footer/footer";
import HomeSectionFour from "./homeSection4";
import HomeSectionFive from "./homeSection5";
import { useState, useEffect } from "react";

const PageHome = ({ videoLoaded, setVideoLoaded }) => {
  return (
    <div>
      <HomeHeroBanner
        videoLoaded={videoLoaded}
        setVideoLoaded={setVideoLoaded}
      />
      <HomeSectionOne />
      <HomeSectionFour />
      <HomeSectionFive />
      <HomeSectionTwo />
      <HomeSectionThree />
    </div>
  );
};

export default PageHome;
