import individualTeammembersDB from "./individualTeamMembersDB";
import "./IndividualTeamMember.css";
import { useParams, useNavigate } from "react-router-dom";
import Bold from "../style/styleBold";

const IndividualTeamMember = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  return (
    <div className="individual-team-page">
      <div className="navigate-back">
        {" "}
        <a href="/bent-christy-graduate-assistance-program-our-team">
          Our Leadearship
        </a>
        <span style={{ margin: "0 .4rem" }}>&#128898;</span>
        {individualTeammembersDB[id].name}
      </div>
      <div className="individual-team-container">
        <div className="individual">
          <div className="photo">
            <img
              src={`
          ${individualTeammembersDB[id].photo}`}
              alt="team-photo"
            />
          </div>
          <div className="content-group">
            <div className="name"> {individualTeammembersDB[id].name}</div>
            <div className="role"> {individualTeammembersDB[id].role}</div>
            <div className="blurb"> {individualTeammembersDB[id].blurb}</div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default IndividualTeamMember;
