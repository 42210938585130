import Red from "../style/styleRed";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import "./register.css";

const Registration = () => {
  const [newUser, setNewUser] = useState({
    firstName: "",
    lastName: "",
    email: "",
    password: "",
    password2: "",
  });
  const [invalidPassword, setInvalidPassword] = useState(false);
  const [successMessage, setSuccessMessage] = useState("");
  const [passNotMatch, setPassNotMatch] = useState(false);
  const [showPassword1, setShowPassword1] = useState(false);
  const [showPassword2, setShowPassword2] = useState(false);
  const navigate = useNavigate();

  const handleShowPassword = (num) => {
    if (num === 1) {
      setShowPassword1((prev) => !prev);
    } else if (num === 2) {
      setShowPassword2((prev) => !prev);
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    if (name === "password") {
      setInvalidPassword(false);
    }
    if (name === "password2") {
      if (e.target.value !== newUser.password) {
        setPassNotMatch(true);
      } else {
        setPassNotMatch(false);
      }
    }
    if (name === "password") {
      if (newUser.password2 !== e.target.value) {
        setPassNotMatch(true);
      } else {
        setPassNotMatch(false);
      }
    }

    setNewUser((prevUser) => ({
      ...prevUser,
      [name]: value,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (newUser.password !== newUser.password2) {
      return;
    }

    const validatePassword = (pass) => {
      return /^(?=.*[A-Z])(?=.*\d)[A-Za-z\d]{8,}$/.test(pass);
    };

    if (!validatePassword(newUser.password)) {
      setInvalidPassword(true);
      return;
    } else {
      setInvalidPassword(false); // Reset invalid password state
    }

    try {
      const response = await fetch("/api/auth/admin-access/register", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(newUser),
      });

      if (!response.ok) {
        setSuccessMessage("Registration failed. Please try again...");
        return;
      }

      setSuccessMessage(
        "Congratulations, your registration was successful! You will receive an email with the confirmation of your privileges shortly."
      );

      setNewUser({
        firstName: "",
        lastName: "",
        email: "",
        password: "",
        password2: "",
      });
    } catch (error) {
      setSuccessMessage("An error occurred. Please try again later.");
      console.error("Error during registration:", error);
    }
  };

  const handleNagivateToLogin = () => {
    setSuccessMessage("");
    navigate("/");
  };

  return (
    <div className="register-page">
      {successMessage && (
        <>
          <div className="success-message">
            <div className="message">{successMessage}</div>

            <div className="ok-btn" onClick={handleNagivateToLogin}>
              OK
            </div>
          </div>
        </>
      )}

      <div className="register-container">
        <h1>Create new account</h1>

        <form onSubmit={handleSubmit}>
          <label htmlFor="firstName">
            First Name <Red>*</Red>
          </label>
          <input
            type="text"
            name="firstName"
            onChange={handleChange}
            value={newUser.firstName}
            required
          />
          <label htmlFor="lastName">
            Last Name <Red>*</Red>
          </label>
          <input
            type="text"
            name="lastName"
            onChange={handleChange}
            value={newUser.lastName}
            required
          />
          <label htmlFor="email">
            Email <Red>*</Red>
          </label>
          <input
            type="email"
            name="email"
            onChange={handleChange}
            value={newUser.email}
            required
          />
          <label htmlFor="password">
            Password <Red>*</Red>
          </label>
          <div className="password-wrapper">
            <input
              type={showPassword1 ? "text" : "password"}
              name="password"
              onChange={handleChange}
              value={newUser.password}
              required
            />
            {passNotMatch && (
              <div className="invalid-password" style={{ margin: ".5rem 0" }}>
                Passwords don't match!
              </div>
            )}
            <img
              onClick={() => handleShowPassword(1)}
              src="https://img.icons8.com/ios-glyphs/30/visible--v1.png"
              alt="visible--v1"
            />
          </div>
          <label htmlFor="password">
            Re-enter Password <Red>*</Red>
          </label>
          <div className="password-wrapper">
            <input
              type={showPassword2 ? "text" : "password"}
              name="password2"
              onChange={handleChange}
              value={newUser.password2}
              required
            />
            <img
              onClick={() => handleShowPassword(2)}
              src="https://img.icons8.com/ios-glyphs/30/visible--v1.png"
              alt="visible--v1"
            />
          </div>

          <div className="invalid-password">
            {invalidPassword && (
              <>
                Password must containt at least 8 characters, including at least
                1 number and 1 upper case letter.
              </>
            )}
          </div>
          <button type="submit">Submit</button>
        </form>
      </div>
    </div>
  );
};

export default Registration;
