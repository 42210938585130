import { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { jwtDecode } from "jwt-decode";
import "./PageAdminAccess.css";
import WelcomeAdmin from "./welcomeAdmin";
import { useUser } from "../userContext";

const AdminPage = () => {
  const [undergradData, setUndergradData] = useState();
  const [parentData, setParentData] = useState();
  const [highschoolData, setHighschoolData] = useState();
  const [archiveConfirm, setArchiveConfirm] = useState(false);
  const [currentIndex, setCurrentIndex] = useState();
  const [currentGroup, setCurrentGroup] = useState();
  const { currentUser } = useUser();
  const [applicationToArchive, setApplicationToArchive] = useState({
    index: null,
    firstName: "",
    lastName: "",
  });

  useEffect(() => {
    fetchAllHighschool();
    fetchAllUndergrads();
    fetchAllParents();
  }, []);

  const baseURL =
    process.env.NODE_ENV === "production" ? "" : "http://localhost:4010";

  const fetchAllUndergrads = async () => {
    try {
      const response = await fetch(`${baseURL}/api/undergrad/get-all`);
      if (response.ok) {
        const data = await response.json();
        setUndergradData(data);
      } else {
        console.error("Applicants not fetched.");
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };

  const fetchAllParents = async () => {
    try {
      const response = await fetch(`${baseURL}/api/parents/get-all`);

      if (response.ok) {
        console.log("Parent applications fetched.");
        const data = await response.json();
        setParentData(data);
      } else {
        console.error("Applicants not fetched.");
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };

  const fetchAllHighschool = async () => {
    try {
      const response = await fetch(`${baseURL}/api/high-school/get-all`);

      if (response.ok) {
        console.log("High School applications fetched.");
        const data = await response.json();
        setHighschoolData(data);
      } else {
        console.error("Applicants not fetched.");
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };

  const archiveApplicant = async (currentGroup, currentIndex) => {
    try {
      const response = await fetch(
        `/api/${currentGroup}/archive-application/${currentIndex}`,

        {
          method: "PATCH",
        }
      );

      if (response.ok) {
        setArchiveConfirm(false);
        fetchAllUndergrads();
        fetchAllParents();
        fetchAllHighschool();
      } else {
        console.error("Applicant not fetched.");
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };

  const handleArchive = (whichGroup, index, firstName, lastName) => {
    setArchiveConfirm(true);
    setCurrentIndex(index);
    setCurrentGroup(whichGroup);
    setApplicationToArchive({ firstName, lastName });
  };

  const handleConfirmArchive = () => {
    archiveApplicant(currentGroup, currentIndex);
  };

  return (
    <>
      <WelcomeAdmin currentUser={currentUser} />
      <div className="admin-page">
        <div
          className="archive-alert"
          style={{ display: archiveConfirm ? "flex" : "none" }}
        >
          <div>
            {" "}
            Are you sure you want to archive {
              applicationToArchive.firstName
            }{" "}
            {applicationToArchive.lastName}'s application?
          </div>
          <div>
            <button onClick={handleConfirmArchive}>Yes</button>{" "}
            <button onClick={() => setArchiveConfirm(false)}>No</button>
          </div>
        </div>

        <div className="list-high-school">
          <h1>Application Management</h1>
          <h2>High School Applications</h2>
          <table>
            <tbody>
              {!highschoolData || highschoolData.length <= 0 ? (
                <div>You don't have any high school applications.</div>
              ) : (
                highschoolData.map((application, index) => {
                  const name = `${application.firstName.toLowerCase()}-${application.lastName.toLowerCase()}`;
                  return (
                    <tr key={index}>
                      <td>{index + 1}</td>
                      <td>
                        {application.firstName} {application.lastName || ""}
                      </td>
                      <td>
                        <div className="button-group">
                          <div className="view-btn">
                            <Link
                              to={`/show-applicant/high-school/${application.school_applicant_id}/${name}`}
                            >
                              View
                            </Link>
                          </div>
                          <div
                            className="archive-btn"
                            onClick={() => {
                              handleArchive(
                                "high-school",
                                application.school_applicant_id,
                                application.firstName,
                                application.lastName
                              );
                            }}
                          >
                            Archive
                          </div>
                        </div>
                      </td>
                    </tr>
                  );
                })
              )}
            </tbody>
          </table>
          <div style={{ marginTop: "2rem" }}></div>
          <h2>Undergraduate Applications</h2>
          <table>
            <tbody>
              {!undergradData || undergradData.length <= 0 ? (
                <div>You don't have any undergraduate applications.</div>
              ) : (
                undergradData.map((application, index) => {
                  const name = `${application.firstName.toLowerCase()}-${application.lastName.toLowerCase()}`;
                  return (
                    <tr key={index}>
                      <td>{index + 1}</td>
                      <td>
                        {application.firstName} {application.lastName || ""}
                      </td>
                      <td>
                        <div className="button-group">
                          <div className="view-btn">
                            <Link
                              to={`/show-applicant/undergrad/${application.undergrad_applicant_id}/${name}`}
                            >
                              View
                            </Link>
                          </div>
                          <div
                            className="archive-btn"
                            onClick={() => {
                              handleArchive(
                                "undergrad",
                                application.undergrad_applicant_id,
                                application.firstName,
                                application.lastName
                              );
                            }}
                          >
                            Archive
                          </div>
                        </div>
                      </td>
                    </tr>
                  );
                })
              )}
            </tbody>
          </table>

          <div style={{ marginTop: "2rem" }}></div>
          <h2>Parent Applications</h2>
          <table>
            <tbody>
              {!parentData || parentData.length <= 0 ? (
                <div>You don't have any parents applications.</div>
              ) : (
                parentData.map((application, index) => {
                  const name = `${application.parentFirstName.toLowerCase()}-${application.parentLastName.toLowerCase()}`;
                  return (
                    <tr key={index}>
                      <td>{index + 1}</td>
                      <td>
                        {application.parentFirstName}{" "}
                        {application.parentLastName || ""}
                      </td>
                      <td>
                        <div className="button-group">
                          <div className="view-btn">
                            <Link
                              to={`/show-applicant/parent/${application.parent_applicant_id}/${name}`}
                            >
                              View
                            </Link>
                          </div>
                          <div
                            className="archive-btn"
                            onClick={() => {
                              handleArchive(
                                "parents",
                                application.parent_applicant_id,
                                application.parentFirstName,
                                application.parentLastName
                              );
                            }}
                          >
                            Archive
                          </div>
                        </div>
                      </td>
                    </tr>
                  );
                })
              )}
            </tbody>
          </table>
        </div>
      </div>
    </>
  );
};

export default AdminPage;
